body {
     
    /* background-image: url('/img/background-app.jpg');
  background-repeat: repeat; */
  background-color: #eceff1;
  background-attachment: fixed;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: auto;

}

iframe {
  pointer-events: none;
}

.MUListItem-Container::marker {
  display: none;
  color: white;
}

ul {
  list-style-type: none !important;
}

.linki {
  text-decoration: none;
  color: white;
}

.ocultar {
	display:none;
}

a {
  text-decoration:none;
  color: #2196f3
}

@media (max-width: 576px) {

  .container {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 100%;
  }  

  #data-map {
    width: 92%;
    left: 1%;
    margin-left: 0px;

  }
}

@media (max-width: 768px) { 
  .grecaptcha-badge { 
    visibility: hidden; 
  }

}

@media (max-width: 960px) {
 
  
}

.border {
  border: 1 px orange;
}

#map { 
  position: absolute;
   top: 0;
   bottom: 0; 
   width: 100%;   
}

.geocoder {
    position: absolute;
    z-index: 1;
    width: 90%;
    left: 10%;
    margin-left: -5%;
    top: 55px;
}

.geocoderx {
  position: absolute;
  z-index: 1;
  width: 90%;
  left: 10%;
  margin-left: -5%;
  top: 10px;
}
  
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}

.swal2-container {
  z-index: 9999 !important;
}

.swal2-title {
  font-size: 16px !important;
}

.swal2-html-container {
  font-size: 14px !important;
}

.swal2-actions {
  width: 100%;
}

.swal2-confirm {
  width: 100%;

}


/* 
#map {
  margin-top: 75px;
} */
